.App {
  text-align: center;
}
.App-header {
  background-color: #282c34;
  background-image: url(./assets//temple_of_promise_at_night_with_moon.jpeg);
  background-position: center;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10vh 10vw;
  box-sizing:border-box;
  font-size: calc(10px + 2vmin);
  color: white;
  text-shadow: black 1px 0 10px;
}